.profile-hero--bg .profile-hero-rating__wrapper {
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.profile-hero--bg .profile-hero-rating__wrapper--telehealth {
    margin-bottom: 0px;
}

.profile-hero--bg .profile-hero-rating__wrapper .profile-hero-ratings {
    -ms-flex-direction: row;
    flex-direction: row;
    display: -ms-flexbox;
    display: flex;
}
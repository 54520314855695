.profile-hero--bg .profile-hero-info__wrapper {
    padding: 40px 0px 20px 15px;
}

.profile-hero--bg .profile-hero-info__specialties {
    margin: 10px 0px;
}

.profile-hero--bg h1,
.profile-hero--bg h2,
.profile-hero--bg h3,
.profile-hero--bg h4,
.profile-hero--bg h5,
.profile-hero--bg h6 {
    color: white;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.rating__stars {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.rating--gradient:not(.rating--tooltip) a {
  color: #343836;
}

.rating--gradient.color-alt a {
  color: white;
}

.rating--gradient .rating__score {
  font-size: 18px;
  font-weight: 700;
  margin-left: 6px;
}

.rating__score {
  display: inline;
}

.rating--gradient .rating-star {
  width: 125px;
  height: 25px;
  background: url("https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/star-bar-gradient.png")
    no-repeat;
  /* background-position: -2px 30px; */
}

.rating--gradient .rating-star.gradient-9 {
  background-position: -2px -264px;
}

.rating--gradient .rating-star.full {
  background-position: -2px -4px;
}
.rating__stars-bar--lg {
  background: url("https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/star-bar.png")
    repeat-x scroll 0 -26px transparent;
  height: 25px;
  width: 125px;
}
.rating__stars-bar {
  overflow: hidden;
}

.rating__stars-bar--lg .rating__stars-bar--progress {
  background: url("https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/star-bar.png")
    repeat-x;
  height: 25px;
  width: 125px;
}

@media (min-width: 768px) {
  .rating {
    text-align: left;
  }
}

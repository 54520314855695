.physician-profile__tabs .physician-profile__creds {
    list-style: none;
    padding-bottom: 15px;
}

.physician-profile__tabs .physician-profile__creds-item,
.physician-profile__tabs .physician-profile__creds-subitem {
    font-size: 14px;
}

@media(min-width: 768px) {
    .physician-profile__tabs .physician-profile__creds {
        margin-bottom: 15px;
        padding-bottom: 0px;
    }

    .physician-profile__tabs .physician-profile__creds-item,
    .physician-profile__tabs .physician-profile__creds-subitem {
        font-size: 18px;
        line-height: 26px;
    }
}
.main-nav__wrapper {
    position: relative;
    background-color: #009ADF;
}

.site-header {
    transition: all 0.2s ease 0s;
    position: relative;
}

.site-header.micro-site-header,
.site-header.root-site-header {
    box-shadow: 0 1px 10px -2px rgba(0,0,0,0.3);
    z-index: 1;
}

.site-header.root-site-header {
    z-index: 101;
}

.site-header.micro-site-header .main-nav,
.site-header.root-site-header .main-nav {
    margin-top: 10px;
}

.site-header.root-site-header .item-border {
    border-top: 1px solid #CCCCCC;
}

.site-header__navs-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

@media(min-width: 768px) {
    .site-header.root-site-header .nav-row ul {
        margin-left: 5px;
        margin-bottom: 5px;
        margin-top: 0px;
        margin-right: 0px;
    }
}

.site-header.root-site-header .nav-row ul {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 16px 0px;
    margin-left: 20px;
}

@media(min-width: 768px)
{
    .site-header.micro-site-header .main-nav__wrapper,
    .site-header.root-site-header .main-nav__wrapper {
        background-color: transparent;
    }
    
    .site-header.micro-site-header .main-nav,
    .site-header.root-site-header .main-nav {
        margin-bottom: 0px;
    }

    .site-header.root-site-header .nav-row ul {
        margin: 25px 0px;
    }

    .site-header__navs-wrapper {
        margin-top: 0px;
    }

    .main-nav {
        position: relative;
        margin: 0px;
    }

    .main-nav.collapse {
        display: block;
    }

    .main-nav__wrapper {
        background-color: #F7F7F7;
    }

    .site-header__navs-wrapper .main-nav__wrapper {
        -ms-flex-order: 2;
        order: 2;
    }
}

@media(min-width: 992px) {
    .site-header__navs-wrapper {
        margin-top: 0px;
    }
}

.site-header.root-site-header .main-nav__wrapper {
    background: white;
}

.site-header.root-site-header .nav-row {
    margin-bottom: 0px;
}
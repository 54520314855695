.flexy-rating {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 20px;
}

.flexy-rating:nth-child(odd) {
    padding-right: 10px;
}

p.fad-physician-profile__criteria--title {
    font-weight: 200;
    margin-bottom: 0px;
    padding-right: 16px;
    font-size: 16px;
    margin-top: 0px;
}

@media(min-width: 768px) {
    p.fad-physician-profile__criteria--title {
        margin-bottom: 0px;
    }
}
* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
}

body {
  display: block;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #34383c;
  font-family: "Open Sans", sans-serif;
  background-color: #ffffff;
  overflow-x: hidden;
}

a {
  background-color: transparent;
  box-sizing: border-box;
  color: #009adf;
  text-decoration: none;
  transition: all 0.2s ease 0s;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

a:link,
a:visited {
  color: #007db8;
  text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

button {
  appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  box-sizing: border-box;
  margin: 0px;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0px;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button:not(.remove-button-style),
input[type="submit"]:not(.remove-button-style) {
  border: 0px;
  color: white;
  border-radius: 0px;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

img {
  border: 0px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
} */

[role="button"] {
  cursor: pointer;
}

/* .collapse {
  display: none;
}

.collapse.in {
  display: block;
} */
*/ .container .container {
  margin-left: -15px;
  margin-right: -15px;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

.hidden {
  display: none !important;
}

.link {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
}

.link .svg-inline--fa {
  font-size: 20px;
  width: 20px;
  text-align: center;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.list-inline {
  padding-left: 0px;
  list-style: none;
}

.list-unstyled {
  padding-left: 0px;
  list-style: none;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav:before,
.nav::after {
  content: " ";
  display: table;
}

.nav-tabs > li {
  margin-top: 18px;
  display: block;
}

.nav-tabs > li > a {
  display: block;
  margin-right: 2px;
  line-height: 1.42857;
  border: 1px solid transparent;
  border-radius: 0px 0px 0px 0px;
}

.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom-color: transparent;
  cursor: default;
}

.typog-primary {
  color: #34383c;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: 1px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 23px;
  }

  /* label {
    font-size: 16px;
    line-height: 23px;
  } */

  .typog-primary {
    font-size: 37px;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 18px;
    line-height: 26px;
  }
  /* 
  label {
    font-size: 18px;
    line-height: 26px;
  } */
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

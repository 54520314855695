.toggle-panel .toggle-panel__body span br {
    display: block;
    height: 50px;
}

.toggle-panel.add-text-fade .toggle-panel__body {
    position: relative;
}

.toggle-panel .body-compressed:not(.show-all) {
    overflow: hidden;
    max-height: calc(20px * 6);
    transition: max-height 1s ease-in-out;
}

.toggle-panel .toggle-panel__fade {
    position: absolute;
    width: 100%;
    height: 150px;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), white);
    opacity: 1;
    transition: opacity 1s ease-in-out;
    z-index: 1;
}

@media(min-width: 768px) {
    .toggle-panel .body-compressed:not(.show-all) {
        min-height: calc(26px * 6);
    }
}
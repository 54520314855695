.anchors-nav__mobile-wrapper {
    background-color: white;
    padding: 5px 30px 10px;
    z-index: 99;
    margin-left: -30px;
    margin-right: -30px;
    border-color: #EAEAEA;
    border-style: solid;
    border-width: 0px 0px 1px;
}

.anchors-nav__wrapper nav {
    font-size: 15px;
}

.anchors-nav__wrapper--alt .anchors-nav {
    margin-top: 74px;
    margin-right: 30px;
    display: block;
}

.anchors-nav__label {
    font-weight: 700;
    margin-bottom: -2px;
}

.anchors-nav__wrapper--alt .anchors-nav .anchors-nav__label {
    font-size: 16px;
}

.anchors-nav .nav > li > a {
    padding: 5px 0px;
    font-size: 16px;
    display: block;
}

.anchors-nav__wrapper nav a:active,
.anchors-nav__wrapper nav a.active {
    color: #007D88;
}

.anchors-nav__wrapper--alt .anchors-nav .nav {
    display: block;
}

.anchors-nav__wrapper--alt .anchors-nav .nav > li {
    position: relative;
    display: block;
}

.anchors-nav__wrapper--alt .anchors-nav .nav > li > a {
    background-color: #F7F7F7;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-weight: bold;
}

.anchors-nav__wrapper--alt .anchors-nav .nav > li > a:hover,
.anchors-nav__wrapper--alt .anchors-nav .nav > li > a:focus,
.anchors-nav__wrapper--alt .anchors-nav .nav > li > a:active,
.anchors-nav__wrapper--alt .anchors-nav .nav > li > .active {
    background-color: #EAEAEA;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);
    transform: translateY(-1px);
}

@media(min-width: 768px) {
    .anchors-nav__modible-wrapper {
        display: none;
    }

    .anchors-nav__wrapper {
        display: block;
        margin-top: 20px;
    }
}
.profile-hero--bg .profile-hero__telehealth,
.profile-hero--bg .profile-hero__online_sched {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.33);
  line-height: 25px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}

.profile-hero--bg .profile-hero__telehealth svg,
.profile-hero--bg .profile-hero__telehealth svg.fa-video,
.profile-hero--bg .profile-hero__online_sched svg,
.profile-hero--bg .profile-hero__online_sched svg.fa-video {
  margin-right: 8px;
}

.fad-filters button.tooltip__btn,
.fad-profile-result__telehealth button.tooltip__btn,
.profile-hero-info__wrapper button.tooltip__btn,
.profile-hero__mobile-flag-row button.tooltip__btn {
    background-color: transparent;
    position: relative;
    top: 3px;
    margin-left: 0px;
    padding-left: 2px;
}

.fad-profile-result__telehealth button.tooltip__btn,
.profile-hero-info__wrapper button.tooltip__btn {
    top: 1px;
}

.profile-hero--bg .profile-hero__telehealth button,
.profile-hero--bg .profile-hero__online_sched button {
    background: transparent;
}

button:not(.remove-button-style).tooltip__btn:hover,
button:not(.remove-button-style).tooltip__btn:focus {
    color: #003CA5;
}
.physician-profile__tabs .tab-content {
    min-height: 360px;
}

.tabbed-display--tab-bar-v2 {
    margin-top: 30px;
}

.tabbed-display--tab-bar-v2 .nav-tabs {
    border-bottom: 2px solid #FFBA00;
}

@media(min-width: 768px) {
    .tabbed-display--tab-bar-v2 {
        margin-top: 40px;
    }

    .tabbed-display--tab-bar-v2 .nav-tabs > li {
        margin-bottom: -2px;
    }

    .tabbed-display--tab-bar-v2 .nav-tabs > li > a {
        font-weight: 500;
    }

    .tabbed-display--tab-bar-v2 .nav-tabs > li.active > a {
        margin-bottom: -2px;
    }

    .tabbed-display--tab-bar-v2 .nav-tabs > li > a.active,
    .tabbed-display--tab-bar-v2 .nav-tabs > li > a.active:hover,
    .tabbed-display--tab-bar-v2 .nav-tabs > li > a.active:focus {
        border: 2px solid #FFBA00;
        border-bottom-color: transparent;
    }

    .tabbed-display--tab-bar-v2 .tab-content {
        margin-top: 20px;
    }
}
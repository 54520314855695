.data-group .card {
  margin: 10px 0px;
}

.data-group .card .location-data-section {
  margin-top: 20px;
}

.data-group .card .location-data-section.first {
  margin-top: 0px;
}

.filterSelect {
  float: right;
  width: 300px;
  margin: 20px;
  color: white !important;
  /* border-radius: 4px !important; */
}

.pagination {
  margin: 15px 1rem 1rem 1rem;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: white ;
  border-color: black ;
  color: black;
}
.pagination > li > a{
  border: 1px solid black ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: black;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: black
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.query_filter_buttons {
    float: left;
    margin-top: 10px;
    margin-left: 1rem;
    color: white !important;
  }
  .radioReport {
    font-size: 13px !important;
    display: inline;
    padding-right:5px
    

  }

  .solrRadio{
    /* margin-right:-15px; */
  }
  
 
.mydropdown {
  
    width:150px;

    
} 

.myControldropdown {
    width:150px;
    height:40px;
    font-size: 1rem;
}

.myMenudropdown {
    width:50px;
    font-size: 1rem;
}
.radioForm{
    display: inline;
}


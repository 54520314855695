.profile-hero--bg .profile-hero-image__wrapper {
    width: 255px;
    margin: 25px 15px;
}

.profile-hero--bg .profile-hero-image__wrapper img {
    width: 100%;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.33);
}

.profile-hero--bg .profile-hero-image__wrapper .profile-image {
    background-color: #b2e0f5;
    width: 255px;
    height: 356px;
    margin: 25px 1px;
    position: relative;
}

.profile-hero--bg .profile-hero-image__wrapper .profile-image .no-photo {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 1.16;
    letter-spacing: -1px;
    transform: translateX(-50%) translateY(-50%);
    content: attr(data-initials);
    color: #009ADF;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
}
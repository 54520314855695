.location-card {
  margin-bottom: 0px;
  max-width: 1200px;
  border-color: #eaeaea;
  border-style: solid;
  border-width: 0px 0px 1px;
}
.locationCard {
  width: 100%;
  height: 100%;
  padding: 10px;
  /* padding-bottom: 67px; */
}
.locationCol {
  padding-right: 0 !important;
}
.locationmapCol {
  padding-left: 0 !important;
}
.locationRow {
  padding-bottom: 30px;
}
.address {
  font-style: normal;
}

.location-card {
  margin-bottom: 0px;
  max-width: 1200px;
  border-color: #eaeaea;
  border-style: solid;
  border-width: 0px 0px 1px;
}

.card__address {
  margin-bottom: 15px;
}

.card__address .address {
  line-height: 1.5 !important;
}

.card__content {
  position: relative;
}

.card__media {
  margin-bottom: 10px;
}

.card__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 5px;
}

.card--location-appointment {
  box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.16), 0px 3px 6px rgb(0, 0, 0, 0.23);
  border-radius: 2px;
  background: white;
  margin: 50px 0px;
}

.card--location-appointment .address {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
}

.card--location-appointment .address__address {
  -ms-flex-order: 2;
  order: 2;
}

.card--location-appointment .address__direction {
  -ms-flex-order: 3;
  order: 3;
}

.card--location-appointment .card__address {
  margin-bottom: 10px;
}

.card--location-appointment .card__content {
  padding-left: 45px;
}

.card--location-appointment .card__media--full-width {
  padding-right: 0px;
}

.card--location-appointment .card-media img {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 768px) {
  .location-card {
    margin: 0px;
    padding: 0px;
    border: none;
  }

  .card__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  /* .card__media {
    margin-bottom: 0px;
  }

  .card--full-width {
    display: -ms-flexbox;
    -ms-flex-pack: justify;
    -ms-flex-align: center;

    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  /* .card__media--full-width {
    -ms-flex-item-align: start;
    /* align-self: flex-start; */
  /*-ms-flex-order: 1;*/
  /* order: 1; */
  /* padding: 0px 15px; */
  /*} */
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
}

@media (min-width: 1200px) {
  /* .card--full-width .card__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
  } */

  .card--location-appointment .card-media img {
    max-width: 120%;
    width: 120%;
    margin-left: -9px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) {
  /* .card--full-width .card__title {
    font-weight: 400;
    font-size: 26px;
  } */
}

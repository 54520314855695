.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.clear_all {
  margin-top: 10px;
}
.filter_breadcrumbs {
  margin-top: 10px;
  margin-right: 5px;
}

.query_buttons {
  float: right;
  margin-top: 10px;
  margin-right: 5px;
  color: black !important;
}
.total {
  float: right;
  font-size: 18px;
  margin-top: 10px;
  margin-right: 5px;
  display: inline;
}
.totalNumber {
  font-weight: 500;
  display: inline;
}
.sr-only {
  /* color: black; */
  position: relative !important;
  font-size: 10px;
  margin-left: 5px !important;
}
.rating {
  width: 218px;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.uri_size {
  word-break: break-all;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filterContainer {
  padding-top: 20px;
}

.sideBarFilter {
  padding-top: 40px;
  padding-bottom: 60px;
  font-size: 12px;
}

.panel-header {
  height: 30px;
  text-align: center;
  padding: 0;
  padding-top: 1%;
}

.count {
  display: inline-flex;
  padding-left: 2px;
  font-size: 8px;
  margin: 0;
}

.loader {
  padding-left: 50%;
  padding-top: 20%;
}
.MuiFormGroup-root {
  display: inline !important;
}
.MuiFormControlLabel-root {
  font-size: 10px !important;
}

.profile-image__initials {
  position: relative;
  background-color: #b2e0f5;
  padding-bottom: 130%;
  width: 100%;
  /* z-index: 1; */
}
.radio {
  font-size: 10px !important;
  font-weight: bold;
}

.ViewProfile {
  color: white !important;
  width: 50%;
}

.incorrect {
  position: absolute;
  bottom: 0;
}
.linkbutton {
  font: bold 11px Arial;
  text-decoration: none;
  background-color: #007bff;
  color: white !important;
  padding: 4px 6px 4px 6px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
}

.profile-image__initials:before {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #34383c;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -1px;
  content: attr(data-initials);
  color: #009adf;
  font-weight: 700;
  text-align: center;
}
.ButtonLink {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069 !important;
  text-decoration: underline;
  cursor: pointer;
}

.ButtonLink:hover,
/* .ButtonLink:focus {
  text-decoration: underline;
} */
.fad-profile-result--nhpp {
  border-left: 3px solid #003ca5 !important;
  border-bottom: 1px solid #cccccc !important;
  background: white;
}
.fad-profile-result {
  padding: 15px;
  border-top: 1px solid #cccccc !important;
  border-bottom: 1px solid #cccccc !important;
  border-left: 1px solid #cccccc !important;
  max-width: 1200px;
  background: #f7f7f7;
}
.nhpp-banner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #cccccc;
  max-width: 695px;
  width: 73%;
  margin-top: 10px;
  font-size: 10px;
  line-height: normal;
  color: #34383c;
}
.nhpp-banner__image-wrapper {
  margin-right: 15px;
}

#react-paginate ul {
  display: inline-block;

  /* padding-left: 15px !important; */
  padding-right: 15px !important;
}

#react-paginate li {
  display: inline-block;
  padding-left: 15px !important;
}
.comment_count {
  margin-top: 0;
  padding-top: 0;
}
.patient_count {
  margin-bottom: 0;
  padding-bottom: 0;
}
.score {
  float: right;
  margin-top: 0;
  font-size: 8px !important;
}
.profile-hero__make-appt .link:link,
.profile-hero__make-appt .link:visited {
  color: white !important;
  font-size: 8px;
}
.appointment {
  float: right;
}

.sideBarCheckbox {
  max-height: 200px;
  overflow: scroll;
}

.fontSize .MuiTypography-body1 {
  font-size: 10px !important;
  padding-bottom: 0;
  margin-bottom: 0;
}

.MuiFormControlLabel-label {
  width: 180px;
}

#root {
  min-height: 100vh;
  padding-bottom: 60px;
  position: relative;
}

.profile-hero--bg__container {
    margin: 0px auto;
    background: #717376 url(https://dam.northwell.edu/m/3a24bb907a5a8570/Drupal-Fad-Hero-BG.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.profile-hero--bg {
    display: --ms-flexbox;
    display: flex;
    overflow: auto;
    color: white;
    margin: 0px auto;
}

@media(min-width: 768px) {
    .profile-hero--bg {
        width: 750px;
    }
}

@media(min-width: 992px) {
    .profile-hero--bg {
        width: 970px;
    }
}
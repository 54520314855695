.fad-physician-profile__comments {
    margin-bottom: 50px;
}

.fad-physician-profile__comments .patient-comments-heading {
    font-size: 26px;
    font-weight: 200;
    margin-top: 25px;
    margin-bottom: 15px;
}

.fad-physician-profile__comments .patient-comments-disclaimer {
    line-height: 1.6;
    border-bottom: 1px solid #AAAAAA;
    padding-bottom: 15px;
}

.fad-physician-profile__comments--view-more {
    transition: max-height 0.3s;
    overflow: hidden;
    max-height: 0px;
}

.fad-physician-profile__comments--view-more.viewing-more {
    max-height: 9999px;
    overflow: hidden;
}

.fad-physician-profile__comments--view-more-btn button,
.fad-physician-profile__comments--view-more-btn button:not(.remove-button-style) {
    color: #007D88;
    padding-left: 0px;
    margin: 35px 0px;
    font-weight: 600;
    background: transparent;
}

.fad-physician-profile__heading {
    font-size: 37px;
    margin-top: 50px;
}

.fad-physician-profile__ratings--info {
    font-weight: 100;
}

.fad-physician-profile__criteria .rating__score {
    font-weight: 600;
    margin-top: 4px;
    padding-left: 5px;
}

.fad-physician-profile__criteria .ratings__stars {
    margin-bottom: 20px;
    cursor: default;
}

.fad-physician-profile__criteria--summary {
    margin-top: 28px;
    margin-bottom: 25px;
    border-bottom: 2px solid #FFBA00;
    padding-bottom: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.fad-physician-profile__criteria--summary .rating--alt {
    display: inline-block;
    padding-left: 0px;
}

.fad-physician-profile__criteria--summary .rating--alt .rating__stars {
    margin-bottom: 0px;
}

.fad-physician-profile__criteria--summary .rating--alt .rating__score {
    margin-top: 4px;
    padding-left: 5px;
    font-size: 21px;
}

.fad-physician-profile__criteria--summary .fad-physician-profile__criteria--number-of-pipe {
    color: #34383C;
    position: relative;
    top: -1px;
    margin-top: 0px 8px 0px 0px;
    display: none;
}

.fad-physician-profile__criteria--summary .fad-physician-profile__criteria--number-of {
    line-height: 12px;
    display: block;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 3px;
}

.fad-physician-profile__rating-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
}

.ratings-anchor {
    text-decoration: none;
    visibility: hidden;
}

@media(min-width: 768px) {
    .fad-physician-profile__criteria--summary .fad-physician-profile__criteria--number-of {
        display: inline-block;
        font-size: 21px;
        font-weight: 600;
        color: #34383C;
        line-height: 21px;
        margin-bottom: -5px;
        margin-top: 0px;
    }

    .fad-physician-profile__criteria--summary .fad-physician-profile__criteria--number-of-pipe {
        display: inline-block;
    }

    .fad-physician-profile__comments--view-more-btn button {
        margin: 0px;
    }
}
.fad-physician-profile__comments--date {
    font-weight: 200;
    color: #717367;
    font-size: 14px;
    padding-bottom: 0px;
}

.fad-physician-profile__comments--text {
    margin-bottom: 20px;
    border-bottom: 1px solid #AAAAAA;
    padding-bottom: 20px;
}
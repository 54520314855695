.jumbotron {
  text-align: center;
  background-color: transparent;
  padding-top: 48px;
  padding-bottom: 48px;
  margin-bottom: 30px;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
  color: #fff;
}

.jumbotron h1,
.jumbotron .h1 {
  font-size: 63px;
}

.lead {
  font-size: 21px;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  margin-top: 10px;
}
.text-family {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
}
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.btn-default {
  color: rgb(0, 0, 0) !important;
  background-color: #fff;

  font-size: 14px;
  border: 1px solid;
  border-color: #ccc;
  padding: 6px 12px;
}
p {
  margin-top: 10px;
}

.btn2 {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.insurance-search-section {
    padding: 40px;
    color: white;
    background-color: #009ADF;
}

.insurance-search-section .insurance-search-section--col {
    display: -ms-flexbox;
    display: flex;
}

.insurance-search-section .insurance-search-section--container {
    width: auto;
    -ms-flex: 2;
    flex: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
}

.insurance-search-section .insurance-search-section--container .search-modal__open {
    background: transparent;
    background-color: transparent;
    padding: 10px;
    border: 2px solid white;
    box-shadow: 0px 4px 2px 1px rgba(0,0,0,0.07) inset, 0px 4px 2px 1px rgba(0,0,0,0.07);
    min-width: 200px;
}

.insurance-search-section h1,
.insurance-search-section h2,
.insurance-search-section h3,
.insurance-search-section h4 {
    color: white;
}

.insurance-search-section a,
.insurance-search-section a:link,
.insurance-search-section a:visited {
    color: white;
}

.insurance-search-section h2 {
    font-size: 37px;
    margin-top: 20px;
}

.insurance-search-section .insurance-search-section--col .container:first-child {
    -ms-flex: 2;
    flex: 2;
}

.insurance-search-section__constellations {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: 100%;
    position: relative;
    margin: 0px auto;
}

.insurance-search-section__constellations .constellation-wrapper {
    right: 0px;
}

.insurance-search-section__constellations .constellation {
    top: -10px;
}

.insurance-search-section__constellations .constellation__1 {
    transform: scale(0.9) translate(-81px, -26px) translateZ(0);
    fill: white;
    opacity: .4;
}

.insurance-search-section__constellations .constellation__2 {
    transform: scale(0.3) translate(175px, 25px) translateZ(0);
    fill: white;
    opacity: .7;
}

.insurance-search-section__constellations .constellation__3 {
    transform: scale(0.2) translateY(730px);
    fill: white;
}

.constellation {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}

.constellation use {
    position: absolute;
    left: 0px;
    top: 0px;
}

.constellation-wrapper {
    display: inline-block;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 280px;
    height: 269px;
    transform-origin: top right;
    overflow: hidden;
}

@media(min-width: 768px) {
    .insurance-search-section__constellations {
        width: 750px;
    }
}

@media(min-width: 992px) {
    .insurance-search-section__constellations {
        width: 970px;
    }
}

@media(min-width: 1200px) {
    .insurance-search-section__constellations {
        width: 1170px;
    }
}


.profile-hero--bg .profile-hero-info__phone-nwhpp-wrapper {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}

.profile-hero--bg .profile-hero__phone {
    color: white;
    text-shadow: 0px 2px 2px rgba(0,0,0,0.33);
    border: 0px;
    width: 250px;
    padding: 0px;
}

.profile-hero--bg .profile-hero__phone.link {
    letter-spacing: 1px;
    font-size: 20px;
}

.profile-hero--bg .profile-hero-info__phone-nwhpp-wrapper svg:not(.svg-inline--fa),
.profile-hero--bg .profile-hero-info__phone-nwhpp-wrapper .profile-hero__nwh-pp--img {
    opacity: 0;
    width: 219px;
    height: 29px;
    animation: fadein 2s ease 0s 1 normal forwards;
    animation-fill-mode: forwards;
}
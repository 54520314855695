.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.main-nav > li {
    display: block;
    position: relative;
    padding: 0px;
}

.main-nav > li > a {
    color: white;
    border-bottom: 1px solid #6DC3DF;
    display: block;
    font-size: 20px;
    padding-bottom: 10px 0px 10px 30px;
}

.site-header.root-site-header .nav-row li {
    margin: 0px 18px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-flow: column;
    flex-flow: column;
}

.site-header.root-site-header .nav-row .fa-user-md {
    color: #009ADF;
}

.site-header.root-site-header .nav-row .fa-database {
    color: #FF681E;
}

.site-header.root-site-header .nav-row .fa-window-maximize {
    color: #9F29B5;
}

.site-header.root-site-header .nav-row .fa-laptop-code {
    color: #3DAD2C;
}

.site-header.root-site-header .nav-row .fa-reply {
    color: #003ca5;
}

@media(min-width: 768px) {
    .main-nav > li {
        display: inline inline-block;
        position: static;
    }

   .site-header.micro-site-header .main-nav > li > a,
   .site-header.root-site-header .main-nav > li > a {
        line-height: 40px;
        height: 40px;
        color: #717376;
        padding-bottom: 50px;
    }

    .site-header.micro-site-header .main-nav > li > a:hover,
    .site-header.root-site-header .main-nav > li > a:hover {
         color: #009ADF;
     }

     .site-header.root-site-header .main-nav > li > a:hover,
     .site-header.root-site-header .main-nav > li > a:focus {
        text-decoration: none;  
        color: black;
      }

    .main-nav > li > a {
        color: #53565A;
        border: none;
        position: relative;
        display: inline-block;
        height: 60px;
        line-height: 60px;
        padding: 0px 15px 0px 0px;
    }
}

@media(min-width: 1200px) {
    .site-header.micro-site-header .main-nav > li > a,
    .site-header.root-site-header .main-nav > li > a {
        font-size: 18px;
    }
}

.site-header.root-site-header .main-nav li a {
    color: black;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    margin-top: 8px;
    font-size: 15px;
}

@media(min-width: 1200px) {
    .site-header.root-site-header .nav-row li {
        -ms-flex-flow: row;
        flex-flow: row;
    }

    .site-header.root-site-header .nav-row li svg {
        font-size: 26px;
    }

    .main-nav > li > a {
        font-size: 20px;
    }

    .site-header.root-site-header .main-nav li a {
        margin-top: 0px;
        font-size: 16px;
    }
}

.site-color-trigger--default .main-nav > li > a{
    border-color: #6DCEDF;
}

@media(min-width: 768px) {
    .site-header.root-site-header .main-nav li > a {
        padding-right: 12px;
        padding: 0px 5px;
        line-height: 16px;
        height: auto;
    }

    .main-nav > li > a {
        font-weight: 700;
        color: #606469;
    }
}

@media(min-width: 1200px) {
    .site-header.root-site-header .main-nav li > a {
        padding-right: 15px;
        padding: 0px 2px;
        line-height: 16px;
        height: auto;
    }
}
.location-showcase {
    background: transparent url('https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/fad-location-bg.png') 0% 0% no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.location-showcase h2 {
    margin: 55px 0px;
    font-size: 37px;
}
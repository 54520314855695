.link--appointment-btn,
.link--appointment-btn:link,
.link--appointment-btn:visited {
    padding: 15px 23px;
    color: white;
    margin-top: 15px;
    display: inline-block;
    border-radius: 2px;
    font-weight: 600;
    position: relative;
    background: #9F26b5;
    background: linear-gradient(180deg, #9F26B5 0%, #87169B 100%);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.profile-hero__make-appt .link--appointment-btn {
    margin-top: 0px;
    margin-bottom: 10px;
}

.profile-hero__make-appt .link:link,
.profile-hero__make-appt .link:visited {
    color: #34383C;
}

.profile-hero--bg .profile-hero__make-appt .link,
.profile-hero--bg .profile-hero__make-appt .link:link,
.profile-hero--bg .profile-hero__make-appt .link:visited,
.profile-hero--bg .profile-hero__make-appt a {
    max-width: 300px;
    color: white;
}